import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("form data:::", e)
    // Access form field values from the event object
    const name = e.target.name.value;
    const email = e.target.email.value;
    const message = e.target.message.value;

    // Construct the message data object
    const messageData = {
      to_name: "Puja",
      from_name: name, // Use the name from the form field
      message: message, // Use the message from the form field
      reply_to: email, // Use the email from the form field
    };

    // Send the email using EmailJS
    emailjs.send("service_w50g5se", "template_hm1e8hf", messageData, "h8jgq5Rk3N6BSVoKz")
      .then((result) => {
        console.log('Email successfully sent:', result.text);
        window.alert("Your message has been sent successfully!");
        // Clear the form fields after submission
        clearState();
      })
      .catch((error) => {
        console.error('Error sending email:', error.text);
        window.alert("An error occurred while sending your message. Please try again later.");
      });
  };



  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Reach out to Puja Shah & Co. today and schedule a consultation with our dedicated team of experts. We are committed to delivering exceptional service and tailored solutions to help you achieve your financial and business objectives.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    value={message}
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <span>
                <i className="fa fa-phone"></i> Phone
              </span>{" "}
              {props.data && props.data.phone && props.data.phone.split(',').map((phoneNumber, index) => (
                <p key={index}>{phoneNumber.trim()}</p>
              ))}
            </div>

            <div className="contact-item">
              <span>
                <i className="fa fa-envelope-o"></i> Email
              </span>{" "}
              {props.data && props.data.email && props.data.email.split(',').map((email, index) => (
                <p key={index}>{email.trim()}</p>
              ))}
            </div>

          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; Copyright 2024 pujashahandco.com. All right reserved.{" "}
            {/* <a href="http://www.templatewire.com" rel="nofollow">
              TemplateWire
            </a> */}
          </p>
        </div>
      </div>
    </div>
  );
};
